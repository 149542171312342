
import React from 'react'

import { Helmet } from 'react-helmet'


export default function MetaHead({
	title,
	description = "Discover, filter, and compare tech products.",
	children,
	url = '/'
}) {

	const fullTitle = title
		? `${title} - hardware.compare`
		: `hardware.compare`

	return <Helmet>
		
		<title>{fullTitle}</title>
		<meta name="title" content={fullTitle} />
		<meta name="description" content={description} />		
		<meta name="theme-color" content="#FFFFFF" />
		<meta name="format-detection" content="telephone=no" />

		<link rel="icon" type="image/svg+xml" sizes="any" href="/favicon.svg" />
		<link rel="icon" href="/favicon.ico" />
		<link rel="apple-touch-icon" href="/favicon-maskable-512.png" />
		<link rel="manifest" href="/manifest.webmanifest" />
		
		{/* Facebook */}
		<meta property="og:site_name" content='hardware.compare' />
		<meta property="og:type" content="website" />
		<meta property="og:url" content={`https://hardware.compare${url}`} />
		<meta property="og:title" content={fullTitle} />
		<meta property="og:description" content={description} />
		<meta property="og:image" content="/banner.jpg" />
		<meta property="og:image:width" content="1200" />
		<meta property="og:image:height" content="630" />
		<meta property="og:locale" content="en_US" />
 
		{/* Twitter */}
		<meta property="twitter:card" content="summary_large_image" />
		<meta property="twitter:url" content="https://hardware.compare" />
		<meta property="twitter:title" content={fullTitle} />
		<meta property="twitter:description" content={description} />
		<meta property="twitter:image" content="/banner.jpg" />
		{/* <meta name="twitter:site" content="@hardware.compare" /> */}
		
		{ children }
		
	</Helmet>
}
